(function ($) {
  Drupal.behaviors.contentBlockVideoV1 = {
    attached: false,
    attach: function (context) {
      const initVideoLaunch = function () {
        var $modules = $('.js-content-block-video--v1', context);

        $modules.each(function () {
          var $self = $(this);
          var $launcher = $('.js-video-launcher', $self);
          var $play = $('.js-play-button-icon', $self);
          var $toutContainer = $('.js-content-block-carousel-arrow-alignment', $self);

          $launcher.colorbox({
            iframe: true,
            width: '100%',
            height: '100%',
            className: 'colorbox__youtube'
          });
          if ($toutContainer.length && $play.length) {
            $toutContainer.prepend($play);
            $toutContainer.addClass('video-launch-trigger').off('click.play').on('click.play', function (videoEvent) {
              videoEvent.preventDefault();
              $launcher.trigger('click');
            });
          }
        });
      };

      initVideoLaunch();
      $(document).on('contentFormatterSlickInitialized', initVideoLaunch);
    }
  };
})(jQuery, Drupal);
